.about-page {
  @include header;

  .embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%;
    margin: 20px 0;
    
    iframe, 
    object, 
    embed { 
      position: absolute; 
      top: 0; 
      left: 0; 
      width: 100%; 
      height: 100%; 
    }
  }
}