.home-page {
	@include header;
	
	.larger-p {
    font-weight: 600;
    font-size: 5rem;
    color: $blue;

    span {
    	font-weight: 100;
    	color: $white;
    }
	}

	// .embed-container {
	// 	position: relative;
	// 	padding-bottom: 56.25%;
	// 	height: 0;
	// 	overflow: hidden;
	// 	max-width: 100%;
	// 	margin: 20px 0;

	// 	video {
	// 		position: absolute;
	// 		top: 0;
	// 		left: 0;
	// 		width: 100%;
	// 		height: 100%;
	// 	}
	// }
	.embed-container { 
		position: relative; 
		padding-bottom: 56.25%; 
		height: 0; 
		overflow: hidden; 
		max-width: 100%;
		margin: 20px 0;

		iframe, 
		object, 
		embed { 
			position: absolute; 
			top: 0; 
			left: 0; 
			width: 100%; 
			height: 100%; 
		}
	}

	.services {
		background: url('img/backgrounds/services-2000.jpg') no-repeat center center;
		background-size: cover;
		overflow: hidden;

		.base-padding {
			padding-top: 220px;
			padding-bottom: 220px;
		}

		.left-arrow-outer {
			position: relative;

			.left-arrow {
				overflow: hidden;
				position: absolute;
				top: 50%;
				left: 0;
				background-color: $black;
				width: 100vw;
				height: 100vw;
				min-width: 100vh;
				min-height: 100vh;
				transform: translate(-75%, -50%) rotate(45deg);

				.left-arrow-inner {
					width: 100%;
					height: 100%;
					position: relative;
					transform: rotate(-45deg);

					.svg-outer {
						position: absolute;
						top: 40%;
						left: 81%;
						width: 50vw;
						height: 50vw;
						min-width: 50vh;
						min-height: 50vh;
					}

					.sub {
						position: absolute;
						top: 46%;
						right: -17%;
						width: 400px;
					}

					@media(max-width: 1199px){
						.sub {
							right: -33%;
							top: 40%;
						}
					}
				}
			}
		}

		.ver-center {
			p.sub {
				position: absolute;
				right: 0;
				top: 50%;
				z-index: 1;
		    transform: translate(-35%, -50%);
			}
		}
	}

	.products_section {
		overflow: hidden;
		background-color: $black;

		.pos-rel {
			position: relative;
		}

		.pos-ab {
			height: 100%;
		}

		.slight-right {
			left: 30px;
			top: -8px;
		}

		.larger-p {
			font-size: 4rem;
			position: absolute;
			top: 50%;
			left: 57%;
			transform: translate(-50%, -50%);
		}

		@media(max-width: 1199px) {
			.larger-p {
				left: 65%;
			}
		}

		// @media(max-width: 991px) {
		// 	.larger-p {
		// 		left: 78%;
		// 	}
		// }
	}
	
	.facebook_section {
		background-color: $black;

		.row {
			display: flex;
			justify-content: center;

			.inner-facebook {
				width: 980px;
				position: relative;
				display: flex;

				img {
					position: absolute;
					top: 0;
					left: 0;
				}

				.fb-page {
					transform: translate(53px, 56px);
					margin-bottom: 100px;
					width: 500px;
					height: 700px;
					flex-shrink: 0;
				}

				.facebook-text {
					margin-left: 200px;
					position: relative;
					z-index: 10;

					.sub {
						line-height: 5rem;

						a {
							color: #fff;
						}
					}

					.smaller-p {
						margin-top: 30px;
						font-size: 5rem;
					}
				}
			}

			@media(max-width: 1000px){
				.inner-facebook {
					flex-wrap: wrap;

					img {
						display: none;
					}

					.fb-page {
						order: 2
					}

					.facebook-text {
						order: 1;
						margin: 0 30px;
					}
				}
			}
		}
	}

	.contact_section {

		#map {
      height: 500px;
      width: 100%;
   	}

   	.info-section {
   		background-color: $black;
   		right: 0;
   		pointer-events: none;
   		z-index: 1;

   		&:before{
			  content: "";
		    position: absolute;
		    top: 0px;
		    left: -35vw;
		    width: 0px;
		    height: 0px;
		    border-left: 35vw solid transparent;
		    border-bottom: 500px solid #17181a;
			}
   	}

   	.centered {
   		pointer-events: all;
	    top: 50%;
	    left: 0%;
    	position: relative;

    	@include tablet {
    		position: absolute;
    		transform: translate(-30%, -40%);
    	}
   	}

   	.pos-rel {
    	height: 100%;
    }
	}
}

@media (min-width: 768px) {
	
}