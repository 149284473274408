
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Main Styles
@import "main-styles";

// Navigation
@import "navigation";

// Footer
@import "footer";

// Home Page
@import "homepage";

// About Page
@import "aboutpage";

// services Page
@import "servicespage";

// Contact Page
@import "contactpage";

// Login Page
@import "loginpage";

// Admin Page
@import "admin";

// Solutions Page
@import "solutionspage";