// Main Colors
$blue: #0171bd;
$grey: #17181a;
$white: #ffffff;
$black: #17181a;
$lightblue: #5cdeee;

// Media Queries 
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

// Headers for pages
@mixin header {
  .main-bg {
    background: url('img/backgrounds/arm-dark-2000.jpg') no-repeat center center;
    background-size: cover;
    height: 80vh;
    
    .sub {
      color: white;
    }
  }

  .pos-rel {
    width: 100%;
    height: 100%;
  }

  .pos-ab {
    width: 100%;
    z-index: 1;
  }

  .center-text {
    text-align: center;
    position: absolute;
    top: 70%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  
    .heading {
      font-size: 4rem;
      color: $blue;
      font-weight: 600;
      margin: 0;

			@include tablet {
		    font-size: 7rem;
		  }

      span {
        color: $white;
        font-weight: 100;
      }
    }
  }
}