.login-page {
  @include header;

  .login-dialogue {
    background-color: $black;

    .main {
      font-size: 5rem;
      color: #5cdeee;
      font-weight: 100;
    }

    input:not([type="checkbox"]) {
      border-radius: 0;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid #fff;
      margin-top: 15px;
      padding: 30px;
      height: 82px;
      font-size: 2rem;
      transition: none;
      color: $lightblue;
      box-shadow: none;

      &:focus, &:hover {
        border-bottom: 2px solid $lightblue;
        outline: none;
      }

      &::placeholder {
        color: $lightblue;
      }
    }

    .checkbox {
      color: #fff;
      margin-top: 20px;

      input {
        display: none;

        + label {
          font-weight: 700;
          font-size: 2rem;
          padding-left: 25px;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 15px;
            height: 15px;
            border: 1px solid $lightblue;
            background-color: rgba(92, 222, 238, 0);
            transition: background-color .25s cubic-bezier(0.475, 0.000, 0.335, 1.895);
          }
        }

        &:checked + label:before {
          background-color: rgba(92, 222, 238, 0.7);
        }
      }
    }

    .forgot {
      color: white;
      font-size: 2rem;
      font-weight: 700;
      width: 100%;
      text-align: right;
      margin-top: 20px;

      a {
        background-color: transparent;
        font-size: inherit;
        padding: 0;
        font-weight: inherit;
        border: none;
        letter-spacing: 0;
        vertical-align: top;
        margin-left: 20px;
        color: $blue;
      }
    }
    @media(max-width: 767px) {
      .forgot {
        text-align: left;
      }
    }

    .button-outer {
      position: relative;
      height: 50px;
      margin: 50px 0;

      button {
        padding: 10px 50px;
        border-radius: 24px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .register {
      background-color: #222;
      padding: 20px;
      text-align: center;
      margin: 0 15px 50px 15px;

      p {
        font-size: 3rem;
        color: #5cdeee;
        font-weight: 100;
        margin: 0;

        a {
          color: #fff;
          font-weight: 400;
        }
      }
    }
  }
}