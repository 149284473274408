.admin {
  * {
    font-size: 16px;
  }
  .navbar-nav>.user-menu>.dropdown-menu {
    width: auto;
  }
}
.d-inline {
    display: inline;
    margin-left: 5px;
}