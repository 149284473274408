html {
	overflow-x: hidden;
}
body {
	font-family: 'Raleway', sans-serif;
	font-size: 10px;
	overflow-x: hidden;
	position: relative;
}

.heading {
	font-size: 3rem;
	color: $blue;
}

.sub {
	font-size: 1.6rem;
	color: $lightblue;
	font-weight: 100;

  @include tablet {
    font-size: 2rem;
	}
	
	a {
		color: $lightblue;
	}
}

.larger {
	font-size: 1.6rem;

  @include tablet {
    font-size: 3rem;    
  }
}

.pos-ab {
	position: absolute;
}

.pos-rel {
	position: relative;
}

.base-padding {
	padding-top: 30px;
	padding-bottom: 30px;
}

.bg-dark {
	background-color: $black;
}

.btn {
	color: #fff;
	background-color: $blue;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 1px;
	transition: color .2s ease-in-out, background-color .2s ease-in-out;

	&:hover {
		background-color: #fff;
		color: $blue;
	}
}

.btn-custom {
	padding: 15px 20px;
	border-radius: 35px;
}

.d-inline-block {
	@include tablet {
		display: inline-block;
		width: 33%;
	}
}

.is-flex {
  @include tablet {
    display: flex;  
  }
}

.mar-bottom {
	margin-bottom: 25px;
}

.no-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.gradient {
	background: linear-gradient(to right, $black, #202e5d);
	padding: 100px 0;
	position: relative;

	.svg-outer {
		position: absolute;
		top: 50px;
		left: 50px;
		width: 50%;
	}

	.row {
		div {
			padding: 30px 50px;

			p {
				color: #fff;
				font-family: 'Raleway', sans-serif;

				&.main {
					font-size: 3rem;
					font-weight: 700;
					margin-bottom: 30px;
				}

				&.content {
					font-size: 2rem;
				}
			}
		}
	}
}

.youtube {
  background-color: #000;
  margin-bottom: 30px;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  cursor: pointer;
	img {
	  width: 100%;
	  top: -16.84%;
	  left: 0;
	  opacity: 0.7;
	}
	.play-button {
	  width: 90px;
	  height: 60px;
	  background-color: #333;
	  box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
	  z-index: 1;
	  opacity: 0.8;
	  border-radius: 6px;

		&:before {
		  content: "";
		  border-style: solid;
		  border-width: 15px 0 15px 26.0px;
		  border-color: transparent transparent transparent #fff;
		}
	}
	img,
	.play-button {
	  cursor: pointer;
	}
	img,
	iframe,
	.play-button,
	.play-button:before {
	  position: absolute;
	}
	.play-button,
	.play-button:before {
	  top: 50%;
	  left: 50%;
	  transform: translate3d( -50%, -50%, 0 );
	}
	iframe {
	  height: 100%;
	  width: 100%;
	  top: 0;
	  left: 0;
	}
}

.beard {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  border: solid 2px #fff;
  border-radius: 100%;
  transition: .3s ease-in-out;
}
.beard:hover {
  border: solid 2px $blue;
}
.beard img {
  width: 100%;
}