footer.social_footer {
	background-color: $black;
	padding-top: 20px;
	padding-bottom: 20px;

	ul {
		display: flex;
		justify-content: space-around;
		list-style: none;
		padding: 0;
		margin: 0;

		li {

			.fa {
				color: $black;
			}

			.fa-stack {
				font-size: 2.7rem;
			}

			.fa-circle {
				color: $blue;
				transition: color .2s ease-in-out;
			}

			&:hover {
				.fa-circle {
					color: #fff;
				}
			}
		}
	}
}