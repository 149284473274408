.contact-page {
  @include header;

	.map_section {
    background-color: $black;
    padding-top: 150px;

		#map {
      height: 500px;
      width: 100%;
   	}

   	.info-section {
   		background-color: $black;
   		right: 0;
   		pointer-events: none;
   		z-index: 1;

   		&:before{
			  content: "";
		    position: absolute;
		    top: 0px;
		    left: -35vw;
		    width: 0px;
		    height: 0px;
		    border-left: 35vw solid transparent;
		    border-bottom: 500px solid #17181a;
			}
   	}

   	.centered {
   		pointer-events: all;
	    top: 50%;
	    left: 0%;
    	position: relative;

    	@include tablet {
    		position: absolute;
    		transform: translate(-30%, -40%);
    	}
   	}

   	.pos-rel {
    	height: 100%;
    }
  }
  
  .form-section {
    background-color: $black;
    padding: 100px 0;

    form {

      &>div {
        padding: 15px;

        input, textarea {
          width: 100%;
          resize: none;
          border: none;
          padding: 15px;
          background-color: #232124;
          color: $lightblue;
          font-size: 2rem;

          &::placeholder {
            color: $lightblue;
          }
        }

        button {
          width: 100%;
          border: none;
          padding: 15px;
          background-color: $blue;
          color: #fff;
          font-size: 2rem;
          transition: color .2s ease-in-out, background-color .2s ease-in-out;

          &:hover {
            color: $blue;
            background-color: #fff;
          }
        }
      }
    }
  }
}