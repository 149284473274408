.services-page {
  @include header;

  .products {
    height: 500px;
    max-height: 100vh;
    position: relative;
		background: url('img/backgrounds/services-2000.jpg') no-repeat center center;
    background-size: cover;
    overflow: hidden;

    .heading {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
      text-align: center;
      font-size: 5rem;

      span {
        color: #fff;
        font-weight: 700;
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 100vw;
      height: 100vw;
      min-width: 100vh;
      min-height: 100vh;
      background-color: $black;
      top: 50%;
      transform: translate(-75%, -40%) rotate(-45deg);

    }
  }
}