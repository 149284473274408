.nav {

	.pos-rel {
		width: 100%;
		height: 100%;
	}

	.pos-ab {
		width: 100%;
		z-index: 1;
	}
	
	.nav-container {
    position: absolute;
    min-width: 2000px;
    width: 115%;
    transform: rotate(3deg);
    margin-left: -1%;
    margin-top: -4%;
	}

	g {
		opacity: .3;
	}

	svg {
		border-bottom: solid 1px $blue;
	}

	.cls-1 {
		fill: url(#New_Gradient_Swatch_1);
	}

	polyline,
	line,
	path {
		fill: none;
		stroke-miterlimit: 10;
		stroke-width: 3px;
		stroke: $blue;
	}

	polyline,
	line {
		stroke-dasharray: 1000;
		stroke-dashoffset: 1000;

		animation: navlines 3s 1s linear forwards;
	}

	path {
		stroke-dasharray: 500;
		stroke-dashoffset: 500;

		animation: navlines .5s 4s linear forwards;
	}

	@keyframes navlines {
		100% {
			stroke-dashoffset: 0;
		}
	}

	.navbar-header {
		margin-left: 10%;
	}
	@media(max-width: 991px) {
		.navbar-header {
			margin-left: 0;
		}
	}

	.navbar-brand {
		position: relative;
		width: 75px;
		padding: 0;

		@include tablet {
			width: 175px;
		}
	}

	.navbar-brand img {
		width: 100%;
		position: absolute;
		bottom: -15px;
	}

	p.logo-text {
		text-align: right;
		position: relative;
		color: $white;
		font-size: 2rem;
		font-weight: 100;
		margin-top: 50px;
		margin-bottom: 0;

		@include tablet {
			text-align: center;
			font-size: 6rem;
		}

		span {
		  text-transform: uppercase;
		  font-size: 1.8rem;
		  font-weight: 600;
		  color: $blue;

		  @include tablet {
				font-size: 4.8rem;
			}
		}
	}
	
	@media(max-width: 1199px) {
		p.logo-text {
			padding-left: 200px;
		}
	}
	
	@media(max-width: 991px) {
		p.logo-text {
			padding-left: 180px;
		}
	}

	.nav {
		font-size: 1.6rem;
	}

	.nav > li > a {
		color: $white;
	}

	.nav > li > a:hover, .nav > li > a:focus {
		background-color: transparent;
	}

	.navbar .navbar-nav {
	  display: inline-block;
	  float: none;
	}

	.navbar .navbar-collapse {
		// text-align: center;
		margin-top: 15px;
		
		@media(max-width: 767px) {
			background-color: rgba(23, 24, 26, 0.9);
		}
	}

	.icon-bar {
		background-color: #fff;
		height: 4px;
		width: 30px;
	}
}


